
import React from 'react'
import AnimatedTitle from './AnimTitle'

function Schedule() {
  return (
    <div className='heading' id='schedule'><AnimatedTitle name="schedule."/></div>
      
  )
}

export default Schedule