export const Talks_data = [
    {
        id: 1,
        name: "Tech-defence Symposium",
        fullname: "Talk on role of electronics in space and defence",
        img: "/images/DRDO.png",
        istheme: false,
        isurl2: false,
        isRule: false,
        isprob: false,
        isTalk: true,
        theme: "electronics sustainability",
        description: `The ETC Engineering Society cordially invites you to partake in our esteemed technical fest, TECHTRONIX. Prepare to be captivated by this exhilarating event as we delve into the fascinating world of electronics in space and defense. We are honored to have Aveek Dutta, a distinguished speaker, to share his valuable insights on this subject. Join us and embark on a journey of knowledge and discovery.

        Mark your calendar for this highly anticipated and exhilarating event. Prepare for an unforgettable experience as knowledge soars to new heights.`,
        rules: ["Size of the poster should be 3x2 ft.", " Participation for the event will be only accepted for groups,each group must contain 2 members.", "Name of student and guide should be present.", "An  abstract within 200 should be submitted before the event to the senior in charge."
        ],
        url: 'https://docs.google.com/forms/u/0/d/1WgckB5c2VZ9g9HyYWQ0tBHa0MkNk8txE1ufpOVowDdY/viewform?edit_requested=true'
    },
    {
        id: 2,
        name: "Drone-Venture Summit",
        fullname: "Talk on Drone-Venture",
        img: "/images/STARTUP.png",
        isimg: true,
        istheme: false,
        isurl2: false,
        isRule: false,
        isprob: false,
        isTalk: true,
        theme: "",
        description: `In the dynamic landscape of the technology industry, drone technology has emerged as a disruptive force with vast potential for innovation and economic growth. As entrepreneurs and investors flock to capitalize on the drone industry's promising future, the Drone-Venture Summit stands out as a crucial platform for charting the source of start-up success in this burgeoning sector.
        The Drone-Venture Summit serves as a melting pot for pioneers, innovators, and investors looking to navigate the ever-evolving drone industry. The summit creates an environment where ideas take flight, fostering collaboration, and showcasing groundbreaking concepts that have the potential to reshape industries.`,
        rules: ["Participation for the event will be only accepted for groups. each group must contain 2 members.", "Round 1 will have 6 questions.", "Out of 6 questions, 3 questions will be using matlab and 3 questions using vivado.", "Round 2 details will be disclosed at the event", "No use of phone Or internet", "Bring your own laptops"
        ],
        url: 'https://docs.google.com/forms/u/0/d/1WgckB5c2VZ9g9HyYWQ0tBHa0MkNk8txE1ufpOVowDdY/viewform?edit_requested=true'
    },


]